
import React, { useState, useEffect } from 'react';
import { Table, Card, Row, Col, Button } from 'react-bootstrap';
import { DashboardPage } from '../DashboardPage/DashboardPage';
import { LoadingRow } from '../../LoadingRow/LoadingRow';
import { loadCategoryGroupEntries,lapEntry } from '../CategoryEntries/Entries/Entries.service';

export const Laps = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const entries = category ? category.entries : [];

  useEffect(() => {
    loadCategoryGroupEntries(match.params.categoryId, setLoading)
      .then(entries => {
        setCategory({entries});
        setLoading(false);
      });
  }, [match.params.categoryId]);

  const addLap = (entry) => {
    console.log(entry);
    lapEntry(entry.id).then(({lap}) => {
      // Find the entry in the category and update its lap count
      const updatedEntries = category.entries.map(e => {
        if (e.id === entry.id) {
          return {
            ...e,
            entry_laps: e.entry_laps ? [...e.entry_laps, lap] : [lap]
          };
        }
        return e;
      });

      // Update the category state with the new entries
      setCategory({
        ...category,
        entries: updatedEntries
      });
    });
  };

  const renderEntryRow = (entry) => (
        <Button 
          variant="outline-primary" 
          size="lg" 
          onClick={() => addLap(entry)}
          className="me-2"
        >
          <b>{entry.bib_number}</b> <small>({entry.entry_laps ? entry.entry_laps.length : 0})</small>
        </Button>
  );

  const sortByLapsNewestTimeAndBibNumber = (a, b) => {
    // First, sort by number of laps completed
    const aLaps = a.entry_laps ? a.entry_laps.length : 0;
    const bLaps = b.entry_laps ? b.entry_laps.length : 0;
    if (aLaps !== bLaps) {
      return aLaps - bLaps; // Descending order
    }
    
    // If laps are the same, sort by newest lap time (oldest first)
    const aNewestTime = a.entry_laps && a.entry_laps.length > 0 ? new Date(a.entry_laps[a.entry_laps.length - 1].time) : new Date(0);
    const bNewestTime = b.entry_laps && b.entry_laps.length > 0 ? new Date(b.entry_laps[b.entry_laps.length - 1].time) : new Date(0);
    if (aNewestTime.getTime() !== bNewestTime.getTime()) {
      return aNewestTime - bNewestTime; // Ascending order (oldest first)
    }
    
    // Lastly, sort by bib number
    return parseInt(a.bib_number, 10) - parseInt(b.bib_number, 10);
  };

  const lappedEntries = entries
    .sort(sortByLapsNewestTimeAndBibNumber);

  return (
    <DashboardPage title={category ? category.name : 'Loading...'} pageTitle="Laps">
        <div className="card">
        <div className="card-header border-0">
    <Row>
    <Col xs={12} className="mb-3" style={{lineHeight: 5}}>
              {lappedEntries.map(renderEntryRow)}
            </Col>
    </Row>
    </div></div>
    </DashboardPage>
  );
};
